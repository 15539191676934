import React, { useEffect, useState } from 'react';
import {
  CarouselProvider,
  Slider,
  Slide,
  Image,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight';
import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { isMobile } from 'react-device-detect';
import ProductCard from './ProductCard';

export default function CardSlider(props) {
  return (
    <CarouselProvider
      naturalSlideWidth={100}
      totalSlides={props.products.length}
      visibleSlides={props.width < 1024 ? 1 : 3}
      infinite
      className="card-gallery"
    >
      <Slider>
        {props.products.map((product, index) => (
          <Slide index={index} className="card-slide">
            <ProductCard
              title={product.title}
              description={product.description}
              image={product.productImage.asset.fluid}
              series={product.series.title}
              category={product.category.title}
              slug={product.slug.current}
              className="carousel-card"
            />
          </Slide>
        ))}
      </Slider>
      <div className="container">
        <ButtonBack>
          <FiChevronLeft />
        </ButtonBack>
        <ButtonNext>
          <FiChevronRight />
        </ButtonNext>
      </div>
    </CarouselProvider>
  );
}
