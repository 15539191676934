import React from 'react';
import {
  CarouselProvider,
  Slider,
  Slide,
  Image,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight';
import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft';
import styled from 'styled-components';
import Img from 'gatsby-image';

export default function ProductSlider(props) {
  const { slides } = props;
  return (
    <CarouselProvider
      naturalSlideWidth={100}
      isIntrinsicHeight
      totalSlides={slides.length}
      infinite
      hasMasterSpinner
      className="homepage_slider_container"
    >
      <Slider>
        {slides.map((slide, index) => (
          <Slide index={index} className="homepage_slider">
            {slide?.featuredVideo ? (
              <video autoPlay loop muted>
                <source
                  poster={slide.featuredImage.asset.fluid.srcWebp || ''}
                  src={slide.featuredVideo.asset.url}
                  type={slide.featuredVideo.asset.mimeType}
                />
              </video>
            ) : (
              <Image src={slide.featuredImage.asset.fluid.srcWebp} isBgImage />
            )}
          </Slide>
        ))}
      </Slider>
      {/* {props.length > 1 && (
    <div className="product-controls--container container">
        <div className="carousel-button-group right">
        <ButtonBack>
            <FiChevronLeft />
        </ButtonBack>
        <ButtonNext>
            <FiChevronRight />
        </ButtonNext>
        </div>
    </div>
    )} */}
    </CarouselProvider>
  );
}
