import React, { useEffect, useState } from 'react';
import {
  CarouselProvider,
  Slider,
  Slide,
  Image,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight';
import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { isMobile } from 'react-device-detect';
import CategoryCard from './CategoryCard';

export default function CategorySlider(props) {
  const { categories, width } = props;
  return (
    <CarouselProvider
      naturalSlideWidth={100}
      totalSlides={categories.length}
      visibleSlides={width <= 768 ? 1 : 3}
      infinite
      className="card-gallery"
    >
      <Slider>
        {categories.map((category, index) => (
          <Slide index={index} className="card-slide">
            <CategoryCard
              title={category.title}
              image={category.categoryImage.asset.fluid}
              slug={category.slug.current}
              className="carousel-card"
            />
          </Slide>
        ))}
      </Slider>
      <div className="container">
        <ButtonBack>
          <FiChevronLeft />
        </ButtonBack>
        <ButtonNext>
          <FiChevronRight />
        </ButtonNext>
      </div>
    </CarouselProvider>
  );
}
